<template>
  <div>
    <div class="vca-page">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  name: "LandingPage",
};
</script>
